(function ($) {

    "use strict";

    $(window).on('load', function () {




        // Load the dev environment or refresh using https
        var loc = window.location.href + '';
        if (loc.indexOf('http://127.0.0.1') == 0) {} else if (loc.indexOf('http://') == 0) {
            window.location.href = loc.replace('http://', 'https://');
        } else if (loc.indexOf('www.') == 0) {
            window.location.href = loc.replace('www.', 'https://');
        }

        $(document).ready(function () {
            $('.shop-catalog').tooltip();
            $('.modal').modal();
            $('.tabs').tabs();
            $('select').formSelect();
            $('.sidenav').sidenav();

            $('.carousel').carousel({
                dist: 0,
                padding: 0,
                fullWidth: true,
                indicators: false,
                duration: 100,
            });

            $('.carousel.carousel-slider').carousel({
                fullWidth: true,
                indicators: false,
                duration: 250,
            });

            $('.moveNextCarousel').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('.carousel').carousel('next');
            });

            $('.movePrevCarousel').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('.carousel').carousel('prev');
            });

            $('.overlay').click(function () {
                return false;
            });

            
            function checkRecaptcha() {
                let izBot = true;
                grecaptcha.execute("6LeI_qQUAAAAAH6ackMF_Klbpgqs3apazGqKRpeB", {
                    action: 'fileUpload'
                }).then(function (token) {
                    $('[name="g-recaptcha-response"]').val(token);
                    izBot = true;
                }, function (reason) {
                    alert('Please reload and try again!');
                    console.log(reason);
                    izBot = false;
                });
                return izBot;
            }

            $('#file-upload-comments').val('');
            M.textareaAutoResize($('#file-upload-comments'));

            $('#file-upload-submit').on('click', function (e) {
                e.preventDefault();
                showLoading();

                if (!checkRecaptcha()) {
                    alert('Please try again later.');
                    return false;
                };

                $("#fileUpload").validate();


                let email_address = $('#file-upload-email').val();

                if(email_address.length == 0)
                 alert('Please enter an email');
                else
               sendForm();
               hideLoading();
            });

            $('#file-upload-input').on('change', function (e) {
                showLoading();
                const fileList = e.currentTarget.files; /* now you can work with the file list */
                $("#fileResults").empty();
                $("#fileResults").append(`Currently uploaded files: `);
                for (var i = 0; i < fileList.length; i++) { //Progress bar and status label's for each file genarate dynamically
                    let currentFile = fileList[i];
                    $("#fileResults").append(`<b><i>${currentFile.name}</i></b> `);
                }
                setTimeout(hideLoading, 1000);
            });

            $('#file-upload-input').on('drop', function (e) {
                showLoading();
                const fileList = e.currentTarget.files; /* now you can work with the file list */
                $("#fileResults").empty();
                $("#fileResults").append(`Currently uploaded files: `);
                for (var i = 0; i < fileList.length; i++) { //Progress bar and status label's for each file genarate dynamically
                    let currentFile = fileList[i];
                    $("#fileResults").append(`<b><i>${currentFile.name}</i></b> `);
                }
            });

            $('#contact-info-submit').on('click', function (e) {
                e.preventDefault();

                if (!checkRecaptcha()) {
                    return false;
                }

                let emailParams= {
                    contact_company_name: $('#contact_company_name').val(),
                    contact_your_name: $('#contact_your_name').val(),
                    contact_email: $('#contact_email').val(),
                    contact_number: $('#contact_number').val(),
                    contact_comments: $('#contact_comments').val()
                }

                emailjs.send('service_ccew2c3', 'template_s831myh', emailParams, 'user_TWiNGv2ibrtzsNLSyNPuG')
                    .then(alert('Contact email has been sent to: info@wildimpact.com'));
                
                document.getElementById('send-contact-info-modal').reset();

                $('#close-contact-us-modal').click();
            });

            autoplay();

            function autoplay() {
                $('.carousel').carousel('next');
                setTimeout(autoplay, 8000);
            }
            
            function showLoading() {
                $("#file-upload-loader").show();
            }

            function hideLoading() {
                $("#file-upload-loader").hide();
            }

            hideLoading();

            async function sendForm() {
                var files = document.getElementById('file-upload-input').files;
                if (files.length === 0) {
                    alert('Please choose a file to upload.');
                    return false;
                }

                showLoading();
                
                await submitToAzure(files).then(function (formattedFileList) {
                    // if there are no files in the return list there was an error
                    if (formattedFileList.length > 0) {

                        let emailParams = {
                            custCompany: $('#file-upload-company-name').val(),
                            custName: $('#file-upload-your-name').val(),
                            custNumber: $('#file-upload-number').val(),
                            custEmail: $('#file-upload-email').val(),
                            comments: $('#file-upload-comments').val(),
                            formattedFileList: formattedFileList.join(" ")
                        };

                        let emails = [];

                        $("#selEmailList > option").each(function () {
                            if (this.selected && this.value !== "") {
                                let toEmail = this.value;
                                emails.push(toEmail);
                            }
                        });
                        
                        // send email to all employees
                        emails.forEach(function (email) {
                            emailParams.email = email;

                            emailjs.send(
                                'service_ccew2c3', 
                                'template_9mhzax9', 
                                emailParams, 
                                'user_TWiNGv2ibrtzsNLSyNPuG'
                            ).then(console.log('Staff email has been sent to: ' + email));
                        });

                        // send email to customer if they provided an email
                        if ($('#file-upload-email').val() !== "") {
                            const confirmParams = {
                                custEmail: $('#file-upload-email').val()
                            }; 
                             
                            emailjs.send('service_ccew2c3', 'template_g8bm7df', confirmParams, 'user_TWiNGv2ibrtzsNLSyNPuG')
                             .then(console.log('Customer email has been sent to: ' + confirmParams.custEmail));
                        }

                        alert('File(s) have been uploaded successfully!');

                        $("#fileResults").empty();
                        $("#fileResults").append(``);
                        document.getElementById('file-upload-form').reset();

                        hideLoading();
                        $('#close-file-upload-modal').click();
                    } else {
                        // error submitting to S3, ask the user to try again
                        alert('File input error: ' + formattedFileList);
                    }
                });
            };
        });


    });
}(jQuery));
